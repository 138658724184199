import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import { setAuthHeader } from './utils/auth'
import { logError } from './utils/logger'  // Add this import

setAuthHeader();

const app = createApp(App)

app.config.errorHandler = (err, vm, info) => {
  axios.post('/api/log', { 
    message: `Global error: ${err.message}\nInfo: ${info}`, 
    type: 'ERROR' 
  }).catch(error => logError('Failed to log error:', error));
};

app.use(router)

app.mount('#app')

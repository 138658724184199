<template>
  <div class="admin">
    <h1>Admin Dashboard</h1>
    <div class="tables">
      <h2>Database Tables</h2>
      <ul>
        <li v-for="table in tables" :key="table" @click="fetchTableData(table)">
          {{ table }}
        </li>
      </ul>
    </div>
    <div class="table-data" v-if="selectedTable">
      <h2>{{ selectedTable }} Data</h2>
      <table>
        <thead>
          <tr>
            <th v-for="header in tableHeaders" :key="header">{{ header }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row in tableData" :key="row.id">
            <td v-for="header in tableHeaders" :key="header">
              {{ formatCellData(row[header]) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue';
import axios from 'axios';
import { logError } from '@/utils/logger'  // Add this import

export default {
  name: 'AdminPage',
  setup() {
    const tables = ref(['users', 'notes']);
    const selectedTable = ref(null);
    const tableData = ref([]);

    const tableHeaders = computed(() => {
      if (tableData.value.length === 0) return [];
      return Object.keys(tableData.value[0]);
    });

    const fetchTableData = async (tableName) => {
      try {
        selectedTable.value = tableName;
        const token = localStorage.getItem('token');
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/admin/${tableName}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        tableData.value = response.data;
      } catch (error) {
        logError(`Error fetching ${tableName} data:`, error);
        alert(`Failed to fetch ${tableName} data. Please check console for details.`);
      }
    };

    const formatCellData = (data) => {
      if (typeof data === 'boolean') {
        return data ? 'Yes' : 'No';
      }
      if (data === null) {
        return 'N/A';
      }
      if (typeof data === 'object') {
        return JSON.stringify(data);
      }
      return data;
    };

    onMounted(() => {
      // We don't need to fetch tables list as we know we have 'users' and 'notes'
    });

    return {
      tables,
      selectedTable,
      tableData,
      tableHeaders,
      fetchTableData,
      formatCellData
    };
  }
}
</script>

<style scoped>
.admin {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}
.tables {
  margin-bottom: 20px;
}
.tables ul {
  list-style-type: none;
  padding: 0;
}
.tables li {
  cursor: pointer;
  padding: 5px;
  margin: 5px 0;
  background-color: #f0f0f0;
  border-radius: 3px;
}
.tables li:hover {
  background-color: #e0e0e0;
}
.table-data {
  background-color: #f8f8f8;
  padding: 15px;
  border-radius: 5px;
  overflow-x: auto;
}
table {
  width: 100%;
  border-collapse: collapse;
}
th, td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}
th {
  background-color: #f2f2f2;
  font-weight: bold;
}
tr:nth-child(even) {
  background-color: #f9f9f9;
}
</style>
<template>
  <div class="home">
    <h1>Welcome to Our App version 2.0</h1>
    <div class="auth-container">
      <div class="auth-section">
        <h2>Sign Up</h2>
        <form @submit.prevent="handleSignUp">
          <input v-model="signUpEmail" type="email" placeholder="Email" required>
          <input v-model="signUpPassword" type="password" placeholder="Password" required>
          <button type="submit">Sign Up</button>
        </form>
      </div>
      <div class="auth-section">
        <h2>Login</h2>
        <form @submit.prevent="handleLogin">
          <input v-model="loginEmail" type="email" placeholder="Email" required>
          <input v-model="loginPassword" type="password" placeholder="Password" required>
          <button type="submit">Login</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import axios from 'axios'; // Don't forget to install axios: npm install axios
import { useRouter } from 'vue-router';
import { logError } from '@/utils/logger'; // Add this import

const router = useRouter();

const signUpEmail = ref('');
const signUpPassword = ref('');
const loginEmail = ref('');
const loginPassword = ref('');

const handleSignUp = async () => {
  try {
    const response = await axios.post('/api/signup', {
      email: signUpEmail.value,
      password: signUpPassword.value
    });
    logError('Sign up successful:', response.data);
    signUpEmail.value = '';
    signUpPassword.value = '';
    alert('Sign up successful! Please log in.');
  } catch (error) {
    logError('Sign up failed:', error.response ? error.response.data : error);
    alert('Sign up failed. Please try again.');
  }
};

const handleLogin = async () => {
  try {
    logError('Attempting login with email:', loginEmail.value);
    const response = await axios.post('/api/login', {
      email: loginEmail.value,
      password: loginPassword.value
    });
    logError('Login response:', response.data);
    localStorage.setItem('token', response.data.token);
    loginEmail.value = '';
    loginPassword.value = '';
    alert('Login successful!');
    router.push('/notes');
  } catch (error) {
    logError('Login error:', error.response ? error.response.data : error);
    alert('Login failed. Please check your credentials and try again.');
  }
};
</script>

<style scoped>
.home {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.auth-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.auth-section {
  width: 45%;
}

form {
  display: flex;
  flex-direction: column;
}

input, button {
  margin-bottom: 10px;
  padding: 8px;
}

button {
  background-color: #4CAF50;
  color: white;
  border: none;
  cursor: pointer;
}

button:hover {
  background-color: #45a049;
}
</style>

<script>
export default {
  name: 'HomePage'
}
</script>
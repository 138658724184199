<template>
  <div v-if="isAuthenticated" class="notes">
    <h1>Notes</h1>
    <div class="new-note">
      <input v-model="newNote" placeholder="Enter a new note" @keyup.enter="addNote">
      <label>
        <input type="checkbox" v-model="newNoteRepeated"> Repeated
      </label>
      <button @click="addNote">Add Note</button>
    </div>
    <ul class="note-list">
      <li v-for="note in notes" :key="note.id" class="note-item">
        <div v-if="editingId !== note.id">
          <strong>{{ note.content }}</strong>
          <p>Created at: {{ new Date(note.created_at).toLocaleString() }}</p>
          <p>Repeated: {{ note.repeated ? 'Yes' : 'No' }}</p>
          <div class="ai-response-box">
            <h3>AI Response:</h3>
            <p v-if="note.ai_response">{{ note.ai_response }}</p>
            <p v-else>No AI response yet.</p>
          </div>
          <button @click="startEdit(note)">Edit</button>
          <button @click="deleteNote(note.id)">Delete</button>
          <button @click="askAI(note.id)">Ask AI</button>
        </div>
        <div v-else>
          <input v-model="editingContent" @keyup.enter="saveEdit">
          <label>
            <input type="checkbox" v-model="editingRepeated"> Repeated
          </label>
          <button @click="saveEdit">Save</button>
          <button @click="cancelEdit">Cancel</button>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue';
import axios from 'axios';
import { useRouter } from 'vue-router';
import { isLoggedIn } from '../utils/auth';
import { logError } from '@/utils/logger';

export default {
  name: 'NotesPage',
  setup() {
    const router = useRouter();
    const isAuthenticated = computed(() => isLoggedIn());

    const notes = ref([]);
    const newNote = ref('');
    const newNoteRepeated = ref(false);
    const editingId = ref(null);
    const editingContent = ref('');
    const editingRepeated = ref(false);
    const error = ref('');

    const fetchNotes = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get('/api/notes', {
          headers: { Authorization: `Bearer ${token}` }
        });
        notes.value = response.data;
      } catch (error) {
        logError('Error fetching notes:', error);
        error.value = 'Failed to fetch notes. Please try again.';
      }
    };

    const addNote = async () => {
      if (newNote.value.trim()) {
        try {
          const token = localStorage.getItem('token');
          const response = await axios.post('/api/notes', {
            content: newNote.value,
            repeated: newNoteRepeated.value
          }, {
            headers: { Authorization: `Bearer ${token}` }
          });
          notes.value.unshift(response.data);
          newNote.value = '';
          newNoteRepeated.value = false;
        } catch (error) {
          logError('Error adding note:', error);
          error.value = 'Failed to add note. Please try again.';
        }
      }
    };

    const deleteNote = async (id) => {
      try {
        const token = localStorage.getItem('token');
        await axios.delete(`/api/notes/${id}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        notes.value = notes.value.filter(note => note.id !== id);
      } catch (error) {
        logError('Error deleting note:', error);
        error.value = 'Failed to delete note. Please try again.';
      }
    };

    const startEdit = (note) => {
      editingId.value = note.id;
      editingContent.value = note.content;
      editingRepeated.value = note.repeated;
    };

    const saveEdit = async () => {
      if (editingContent.value.trim()) {
        try {
          const token = localStorage.getItem('token');
          const response = await axios.put(`/api/notes/${editingId.value}`, {
            content: editingContent.value,
            repeated: editingRepeated.value
          }, {
            headers: { Authorization: `Bearer ${token}` }
          });
          const index = notes.value.findIndex(note => note.id === editingId.value);
          notes.value[index] = response.data;
          editingId.value = null;
        } catch (error) {
          logError('Error updating note:', error);
          error.value = 'Failed to update note. Please try again.';
        }
      }
    };

    const cancelEdit = () => {
      editingId.value = null;
      editingContent.value = '';
      editingRepeated.value = false;
    };

    const askAI = async (noteId) => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.post(`/api/notes/${noteId}/ask-ai`, {}, {
          headers: { Authorization: `Bearer ${token}` }
        });
        const updatedNote = response.data;
        const index = notes.value.findIndex(note => note.id === noteId);
        if (index !== -1) {
          notes.value[index] = updatedNote;
        }
        logError('AI response received:', updatedNote.ai_response);
      } catch (error) {
        logError('Error asking AI:', error);
        if (error.response) {
          logError('Server responded with:', error.response.data);
        } else if (error.request) {
          logError('No response received from server');
        } else {
          logError('Error:', error.message);
        }
      }
    };

    onMounted(() => {
      if (!isAuthenticated.value) {
        router.push({ name: 'HomePage' });
      } else {
        fetchNotes();
      }
    });

    return {
      notes,
      newNote,
      newNoteRepeated,
      editingId,
      editingContent,
      editingRepeated,
      addNote,
      deleteNote,
      startEdit,
      saveEdit,
      cancelEdit,
      askAI,
      isAuthenticated,
      error
    };
  }
}
</script>

<style scoped>
.notes {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}
.new-note {
  margin-bottom: 20px;
}
.note-list {
  list-style-type: none;
  padding: 0;
}
.note-item {
  margin-bottom: 20px;
  padding: 15px;
  background-color: #f0f0f0;
  border-radius: 5px;
}
button {
  margin-left: 10px;
}
.note-item p {
  margin: 5px 0;
}
.ai-response-box {
  background-color: #e6f7ff;
  border: 1px solid #91d5ff;
  border-radius: 4px;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.ai-response-box h3 {
  margin-top: 0;
  color: #0050b3;
}
</style>
import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '../views/Home.vue'
import NotesPage from '../views/Notes.vue'
import AdminPage from '../views/AdminPage.vue'
import TestPage from '../views/Test.vue'
import { isLoggedIn } from '../utils/auth'

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage
  },
  {
    path: '/notes',
    name: 'NotesPage',
    component: NotesPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/admin',
    name: 'AdminPage',
    component: AdminPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/test',
    name: 'TestPage',
    component: TestPage,
    meta: { requiresAuth: true }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!isLoggedIn()) {
      next({ name: 'HomePage' });
    } else {
      next();
    }
  } else {
    next();
  }
})

export default router
<template>
  <div id="app">
    <nav>
      <router-link to="/">Home</router-link> |
      <router-link to="/notes">Notes</router-link> |
      <router-link to="/admin">Admin</router-link> |
      <router-link to="/test">Test AI</router-link>
    </nav>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>
/* Add your styles here */
</style>

<template>
  <div class="test">
    <h1>AI Test Page</h1>
    <div class="input-container">
      <textarea v-model="userInput" placeholder="Enter your question here"></textarea>
      <button @click="askAI">Ask AI</button>
    </div>
    <div v-if="aiResponse" class="response-container">
      <h2>AI Response:</h2>
      <p>{{ aiResponse }}</p>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import axios from 'axios';
import { logError } from '@/utils/logger';  // Add this import

export default {
  name: 'TestPage',
  setup() {
    const userInput = ref('');
    const aiResponse = ref('');

    const askAI = async () => {
      if (!userInput.value.trim()) return;

      try {
        const token = localStorage.getItem('token');
        const response = await axios.post('/api/ask-ai', 
          { prompt: userInput.value },
          { headers: { Authorization: `Bearer ${token}` } }
        );
        aiResponse.value = response.data.result;
      } catch (error) {
        logError('Error asking AI:', error);  // Replace console.error with logError
        aiResponse.value = 'Error occurred while processing your request.';
      }
    };

    return {
      userInput,
      aiResponse,
      askAI
    };
  }
}
</script>

<style scoped>
.test {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}
.input-container {
  margin-bottom: 20px;
}
textarea {
  width: 100%;
  height: 100px;
  margin-bottom: 10px;
}
button {
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  cursor: pointer;
}
button:hover {
  background-color: #45a049;
}
.response-container {
  background-color: #f0f0f0;
  padding: 15px;
  border-radius: 5px;
}
</style>